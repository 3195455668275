import _compression from "compression";
import _key from "utilise/key";
import _path from "path";
import _express from "express";
import _serveStatic from "serve-static";
import _log from "utilise/log";
var exports = {};

// -------------------------------------------
// Serves the /pages directory
// -------------------------------------------
exports = function pages(ripple, {
  server,
  dir,
  static: _static = "./static"
} = {}) {
  log("creating");
  const {
    http = server
  } = ripple.server || {};
  if (!http || !dir) return ripple;
  expressify(http).use(compression(), serve(resolve(dir, _static), {
    redirect: false
  })).use("*", compression(), serve(resolve(dir, _static)));
  return ripple;
};

const expressify = server => server.express || key("_events.request")(server) || server.on("request", express())._events.request;

const compression = _compression,
      key = _key,
      {
  resolve
} = _path,
      express = _express,
      serve = _serveStatic,
      log = _log("[ri/pages]");

export default exports;